<style >
.hideColoum{
  display: none !important;
}
</style>
<template>
    <div>
        <Row :gutter="5" class="m-b-10">
            <i-col :xs="24" :sm="12" :md="6" class="m-b-5">
                <DatePicker size="small" placeholder="确认单签订时间"  v-model="signDate" type="daterange" style="width:100%"></DatePicker>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <DatePicker size="small" placeholder="确认单开始结束时间" v-model="publishDate" type="daterange" style="width:100%"></DatePicker>
            </i-col>

      <i-col  v-if="isAuth('Agreement_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
        <Select size="small" v-model="agreementQuery.companyId" placeholder="所属公司" clearable @on-change="companyChange">
          <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
        </Select>
      </i-col>
         <i-col  v-if="isAuth('Agreement_Manage_View')" :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="agreementQuery.departmentId" placeholder="所属部门" clearable @on-change="departmentChange">
                  <Option v-for="(department,index) in departments" :key="index" :value="department.id">{{department.name}}</Option>
                </Select>
            </i-col>
            <i-col v-if="isAuth('Agreement_Manage_View')"  :xs="24" :sm="12" :md="6" class="m-b-5">
                <Select size="small" v-model="agreementQuery.userId" placeholder="归属销售" clearable>
                  <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
                </Select>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-select size="small" placeholder="状态" v-model="agreementQuery.status" multiple >
                    <i-option v-for="item in statusArray" :key='item.id' :value="item.id">{{item.name}}</i-option>
                </i-select>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-input size="small" placeholder="关键字：支持名称查询" v-model="agreementQuery.keyword"></i-input>
            </i-col>
            <i-col  :xs="24" :sm="12" :md="6" class="m-b-5">
                <i-button size="small" type="primary" icon='ios-search'  @click="handleSearch">搜索</i-button>
                <i-button size="small"  type="success" class="m-l-5" v-if="isAuth('Agreement_DownloadList')"  @click="handleExportExcel">导出Excel</i-button>
            </i-col>
        </Row>

        <Table stripe :data="list" :columns="isAuth('Agreement_Manage_View')?tableColumns:tableColumnsSale"  @on-sort-change="handleTableSort"></Table>
        <div class="paging_style">
          <Page size="small" :total="total" :page-size="agreementQuery.pageSize" show-total show-elevator :current="agreementQuery.pageNumber" @on-change="handlePageChanged"></Page>
        </div>
        </div>
</template>

<script>
import { getContractPage, deleteContract, invalidContract } from '@/api/scp/contract'
// import { getOrderAdvertiser } from '@/api/order/order'

import { toMoney } from '@/utils/wnumb_own'
import { GetPublishDay, GetCurrentSchedule } from '@/utils/dateFormat'
import { formatContractStatus, formatContractRemark } from '@/utils/tagStatus'
import { downloadFileRequest } from '@/utils/download'

import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'

export default {
  data () {
    return {
      channellist: [],
      departments: [],
      channelUsers: [],
      total: 0,
      list: [],
      tableColumns: [
        {
          title: '编号',
          key: 'code',
          sortable: 'custom'
        },
        { title: '名称', key: 'name' },
        { title: '发布类型', key: 'contractCategoryName' },
        {
          title: '所属代理商/销售',
          key: 'companyName',
          render: (h, params) => {
            return h('div', [
              h(
                'p',
                params.row.companyName
              ),
              h(
                'p',
                params.row.userName
              )
            ])
          }
        },
        {
          title: '起止日',
          render: (h, params) => {
            return h('span', this.formatSchuedle(params.row).value)
          }
        },
        {
          title: '总额',
          key: 'amount',
          sortable: 'custom',
          render: (h, params) => {
            return h('strong', {
              attrs: {
                style: 'color:#44bd32'
              }
            },
            toMoney(params.row.amount))
          }
        },
        {
          title: '已收款',
          key: 'receivedAmount',
          sortable: 'custom',
          render: (h, params) => {
            return h('strong', {
              attrs: {
                style: 'color:#44bd32'
              }
            },
            toMoney(params.row.receivedAmount))
          }
        },
        {
          title: '备注',
          render: (h, data) => {
            return formatContractRemark(h, data.row)
          }
        },
        {
          title: '状态',
          width: 120,
          render: (h, data) => {
            return formatContractStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const updateButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleEdit(data.row)
                }
              }
            }, '编辑')
            const deleteButton = h('a', {
              style: {
                marginRight: '5px',
                color: '#ef4f4f'
              },
              on: {
                click: () => {
                  this.handleDelete(data.row.id)
                }
              }
            }, '删除')
            const detailButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleDetail(data.row)
                  this.$store.commit('set_contract_contractId', 0)
                  this.$store.commit('set_contract_contractSetting', false)
                }

              }
            }, '详情')
            // const settingButton = h('a', {
            //   style: {
            //     marginRight: '5px'
            //   },
            //   on: {
            //     click: () => {
            //       this.$store.commit('set_contract_contractId', data.row.id)
            //       this.$store.commit('set_contract_contractSetting', true)
            //     }

            //   }
            // }, '设置')

            const returnBtn = [detailButton]
            // if (this.isAuth('Contract_FileSetting')) {
            //   returnBtn.push(settingButton)
            // }
            if (this.isAuth('Agreement_Edit')) {
              if (data.row.status === 0) {
                returnBtn.push(updateButton)
                returnBtn.push(deleteButton)
              }
              if (data.row.status === 6) {
                returnBtn.push(updateButton)
              }
            }

            return h('div', returnBtn)
          }
        }
      ],
      tableColumnsSale: [
        { title: '编号', key: 'code', sortable: 'custom' },
        { title: '名称', key: 'name' },
        { title: '发布类型', key: 'contractCategoryName' },
        {
          title: '起止日',
          render: (h, params) => {
            return h('span', this.formatSchuedle(params.row).value)
          }
        },
        {
          title: '总额',
          key: 'amount',
          sortable: 'custom',
          render: (h, params) => {
            return h('strong', {
              attrs: {
                style: 'color:#44bd32'
              }
            },
            toMoney(params.row.amount))
          }
        },
        {
          title: '已收款',
          key: 'receivedAmount',
          sortable: 'custom',
          render: (h, params) => {
            return h('strong', {
              attrs: {
                style: 'color:#44bd32'
              }
            },
            toMoney(params.row.receivedAmount))
          }
        },
        {
          title: '备注',
          render: (h, data) => {
            return formatContractRemark(h, data.row)
          }
        },
        {
          title: '状态',
          width: 120,
          align: 'center',
          render: (h, data) => {
            return formatContractStatus(h, data.row.status, data.row.statusName)
          }
        },
        {
          title: '操作',
          render: (h, data) => {
            const updateButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleEdit(data.row)
                }
              }
            }, '编辑')
            const deleteButton = h('a', {
              style: {
                marginRight: '5px',
                color: '#ef4f4f'
              },
              on: {
                click: () => {
                  this.handleDelete(data.row.id)
                }
              }
            }, '删除')
            const invalidButtom = h('a', {
              style: {
                marginRight: '5px',
                color: '#ef4f4f'
              },
              on: {
                click: () => {
                  this.handleInvalid(data.row.id)
                }

              }
            }, '作废')
            const detailButton = h('a', {
              style: {
                marginRight: '5px'
              },
              on: {
                click: () => {
                  this.handleDetail(data.row)
                  this.$store.commit('set_contract_contractId', 0)
                  this.$store.commit('set_contract_contractSetting', false)
                }

              }
            }, '详情')
            // const settingButton = h('a', {
            //   style: {
            //     marginRight: '5px'
            //   },
            //   on: {
            //     click: () => {
            //       this.$store.commit('set_contract_contractId', data.row.id)
            //       this.$store.commit('set_contract_contractSetting', true)
            //     }

            //   }
            // }, '设置')

            const returnBtn = [detailButton]
            // if (this.isAuth('Contract_FileSetting')) {
            //   returnBtn.push(settingButton)
            // }

            if (this.isAuth('Agreement_Edit')) {
              if (data.row.status === 0) {
                returnBtn.push(updateButton)
                returnBtn.push(deleteButton)
              }
              if (data.row.status === 6) {
                returnBtn.push(updateButton)
                returnBtn.push(invalidButtom)
              }
            }
            return h('div', returnBtn)
          }
        }
      ],
      signDate: [],
      publishDate: [],
      statusArray: []
    }
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      } else {
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      }
    },
    formatSchuedle (data) {
      return {
        value: GetCurrentSchedule(data.startDate, data.endDate),
        days: GetPublishDay(data.startDate, data.endDate)
      }
    },
    initPage () {
      this.channellist = []
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.agreementQuery.companyId) {
          if (array.length > 1 && this.isAuth('Agreement_Manage_View')) {
            this.agreementQuery.companyId = null
          } else {
            this.agreementQuery.companyId = this.$store.getters.token.userInfo.companyId
          }
        }
        this.initdepartments()
      })
    },
    companyChange () {
      this.agreementQuery.departmentId = ''
      this.agreementQuery.userId = ''
      this.initdepartments()
    },
    departmentChange () {
      this.agreementQuery.userId = ''
      this.getChannelUserList()
    },
    initdepartments () {
      this.departments = []
      if (this.agreementQuery.companyId) {
        getCompanyChild({ companyId: this.agreementQuery.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departments = array
          // this.agreementQuery.departmentId = null
          this.getChannelUserList()
        })
      } else {
        this.getChannelUserList()
      }
    },

    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    getChannelUserList () {
      this.channelUsers = []
      const data = {
        companyId: this.agreementQuery.departmentId || this.agreementQuery.companyId,
        pageNumber: 1,
        pageSize: 1000
      }
      if (data.companyId) {
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.agreementQuery.userId = ''
      }
      this.getPage()
    },
    getPage () {
      if (this.agreementQuery.status.length > 0) {
        this.agreementQuery.statusList = JSON.stringify(this.agreementQuery.status)
      } else {
        this.agreementQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }

      this.$store.commit('set_query_agreementQuery', this.agreementQuery)
      getContractPage(this.agreementQuery).then(res => {
        this.list = res.list
        this.total = res.totalRow
      })
    },
    handleDetail (contract) {
      this.$emit('on-detail', contract.bid, contract.versionId)
    },
    handleEdit (contract) {
      this.$emit('on-edit', contract.bid, contract.versionId)
    },
    handleInvalid (contractId) {
      let desc = ''
      this.$Modal.confirm({
        title: '作废合同',
        render: h => {
          return h('Input', {
            props: {
              value: desc,
              placeholder: '请输入作废理由'
            },
            on: {
              'on-change': event => {
                desc = event.target.value
              }
            }

          })
        },
        onOk: () => {
          invalidContract({ bid: contractId, invalidMark: desc }).then(
            res => {
              if (res && !res.errcode) {
                this.$Notice.success({ desc: '合同作废成功', title: '操作成功' })
                this.getPage()
              }
            }
          )
        }
      })
    },
    handleDelete (contractId) {
      this.$Modal.confirm({
        title: '操作提醒',
        content: '您将删除该合同，点击确认后将删除',
        onOk: () => {
          deleteContract({ contractId: contractId }).then(res => {
            if (res && !res.errcode) {
              this.$Notice.success({
                desc: '合同删除成功'
              })
              this.agreementQuery.pageNumber = 1
              this.getPage()
            }
          })
        }

      })
    },
    handlePageChanged (page) {
      this.agreementQuery.pageNumber = page
      this.getPage()
    },
    handleSearch () {
      this.agreementQuery.pageNumber = 1
      this.agreementQuery.startDate = this.formatDate(this.publishDate[0])
      this.agreementQuery.endDate = this.formatDate(this.publishDate[1])
      this.agreementQuery.signStartDate = this.formatDate(this.signDate[0])
      this.agreementQuery.signEndDate = this.formatDate(this.signDate[1])
      this.getPage()
    },

    handleTableSort (column) {
      this.agreementQuery.pageNumber = 1
      switch (column.key) {
        case 'code':
          this.agreementQuery.orderByCodeAsc = (column.order === 'asc')
          break
        case 'amount':
          this.agreementQuery.orderByAmountAsc = (column.order === 'asc')
          break
        case 'receivedAmount':
          this.agreementQuery.orderByReceivedAmountAsc = (column.order === 'asc')
          break
      }
      this.getPage()
    },
    handleExportExcel () {
      if (this.agreementQuery.status.length > 0) {
        this.agreementQuery.statusList = JSON.stringify(this.agreementQuery.status)
      } else {
        this.agreementQuery.statusList = JSON.stringify(this.statusArray.map(x => { return x.id }))
      }
      const query = Object.assign({}, this.agreementQuery, { subjectTypeList: JSON.stringify([4]) })
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-scp/v1/contractfile/exportexcel', query, '确认单列表.xlsx')
    },
    // 加载可用的状态
    initStatusArray () {
      const that = this
      that.statusArray = [
        { id: 0, name: '草稿' }
      ]
      const manageStatus = [
        { id: -1, name: '已废弃' },
        { id: 4, name: '待审批' },
        { id: 5, name: '已通过' },
        { id: 6, name: '已拒绝' }
        // { id: 9, name: '已归档' }
      ]
      if (that.isAuth('Agreement_Sale_View')) {
        that.statusArray = that.statusArray.concat(manageStatus)
      } else {
        that.statusArray = manageStatus
      }
    }

  },
  created () {
    this.agreementQuery.userId = this.isAuth('Agreement_Manage_View') ? this.agreementQuery.userId : this.$store.getters.token.userInfo.userId
    this.initStatusArray()
    this.initPage()
  },
  computed: {
    agreementQuery () {
      return this.$store.state.contractQuery.agreementQuery
    }
  }
}
</script>
